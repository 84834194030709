import React from "react"
import PropTypes from "prop-types"

function TitleUnderlined({
  content,
  colorTitle,
  classList,
  tag,
  lineWidth,
  titleAlign,
  headingClassList,
  color,
  fontSize,
}) {
  let textAlign
  let lineAlign
  let sizeFont

  switch (fontSize) {
    case "32px":
      sizeFont = "32px"
      break
    case "42px":
      sizeFont = "42px"
      break
    case "64px":
      sizeFont = "64px"
      break
    default:
      sizeFont = null
  }

  switch (titleAlign) {
    case "Center":
      textAlign = "text-center"
      lineAlign = "mx-auto"
      break
    case "Center Mobile":
      textAlign = "text-center lg:text-left"
      lineAlign = "mx-auto lg:mx-0"
      break
    case "Center Desktop":
      textAlign = "text-left lg:text-center"
      lineAlign = "mx-0 lg:mx-auto"
      break
    case "Left":
      textAlign = "text-left"
      lineAlign = "mx-0"
      break
    case "Right":
      textAlign = "text-right lg:text-left"
      lineAlign = "ml-auto mr-0 lg:mx-0"
      break
    default:
      textAlign = "text-left"
      lineAlign = "mx-0"
  }

  return (
    <div className="block w-full mb-4" data-layer-component="title-underlined">
      {(tag === "h1" && (
        <div className={`${textAlign} relative ${headingClassList}`}>
          <h1
            className={`inline-block transform -rotate-2 leading-none ${classList} ${
              colorTitle || "text-black"
            }`}
            style={{ color: color || null, fontSize: sizeFont }}
          >
            {content}
          </h1>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`fill-current ${lineAlign} ${colorTitle} ${lineWidth}`}
            viewBox="0 0 96 14"
          >
            <path
              fillRule="evenodd"
              d="M0 13.04V10.1l26.3-3.47L51.04 4.9 93.61.58l1.9 3.29L50.7 8.7l-6.4 1.04s-5.71.35-6.58.35c-.87 0-7.61.69-7.61.69l-2.6-.52-3.8 1.04-3.81.34s-3.12-.52-3.63-.52c-.52 0-1.56.35-2.43.87C12.98 12.52 0 13.04 0 13.04Z"
              clipRule="evenodd"
              fill={color || null}
            />
          </svg>
        </div>
      )) ||
        (tag === "h2" && (
          <div className={`${textAlign} relative ${headingClassList}`}>
            <h2
              className={`relative inline-block transform -rotate-2 leading-none ${classList} ${
                colorTitle || "text-black"
              }`}
              style={{ color: color || null, fontSize: sizeFont }}
            >
              {content}
            </h2>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`fill-current ${lineAlign} ${colorTitle} ${lineWidth}`}
              viewBox="0 0 96 14"
            >
              <path
                fillRule="evenodd"
                d="M0 13.04V10.1l26.3-3.47L51.04 4.9 93.61.58l1.9 3.29L50.7 8.7l-6.4 1.04s-5.71.35-6.58.35c-.87 0-7.61.69-7.61.69l-2.6-.52-3.8 1.04-3.81.34s-3.12-.52-3.63-.52c-.52 0-1.56.35-2.43.87C12.98 12.52 0 13.04 0 13.04Z"
                clipRule="evenodd"
                fill={color || null}
              />
            </svg>
          </div>
        )) ||
        (tag === "h3" && (
          <div className={`${textAlign} relative ${headingClassList}`}>
            <h3
              className={`relative inline-block transform -rotate-2 leading-none ${classList} ${
                colorTitle || "text-black"
              }`}
              style={{ color: color || null, fontSize: sizeFont }}
            >
              {content}
            </h3>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`fill-current ${lineAlign} ${colorTitle} ${lineWidth} rotate-2`}
              viewBox="0 0 96 14"
            >
              <path
                fillRule="evenodd"
                d="M0 13.04V10.1l26.3-3.47L51.04 4.9 93.61.58l1.9 3.29L50.7 8.7l-6.4 1.04s-5.71.35-6.58.35c-.87 0-7.61.69-7.61.69l-2.6-.52-3.8 1.04-3.81.34s-3.12-.52-3.63-.52c-.52 0-1.56.35-2.43.87C12.98 12.52 0 13.04 0 13.04Z"
                clipRule="evenodd"
                fill={color || null}
              />
            </svg>
          </div>
        )) ||
        (tag === "h4" && (
          <div className={`${textAlign} relative ${headingClassList}`}>
            <h4
              className={`relative inline-block transform -rotate-2 leading-none ${classList} ${
                colorTitle || "text-black"
              }`}
              style={{ color: color || null, fontSize: sizeFont }}
            >
              {content}
            </h4>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`fill-current ${lineAlign} ${colorTitle} ${lineWidth}`}
              viewBox="0 0 96 14"
            >
              <path
                fillRule="evenodd"
                d="M0 13.04V10.1l26.3-3.47L51.04 4.9 93.61.58l1.9 3.29L50.7 8.7l-6.4 1.04s-5.71.35-6.58.35c-.87 0-7.61.69-7.61.69l-2.6-.52-3.8 1.04-3.81.34s-3.12-.52-3.63-.52c-.52 0-1.56.35-2.43.87C12.98 12.52 0 13.04 0 13.04Z"
                clipRule="evenodd"
                fill={color || null}
              />
            </svg>
          </div>
        )) ||
        (tag === "h5" && (
          <div className={`${textAlign} relative ${headingClassList}`}>
            <h5
              className={`relative inline-block transform -rotate-2 leading-none ${classList} ${
                colorTitle || "text-black"
              }`}
              style={{ color: color || null, fontSize: sizeFont }}
            >
              {content}
            </h5>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`fill-current ${lineAlign} ${colorTitle} ${lineWidth}`}
              viewBox="0 0 96 14"
            >
              <path
                fillRule="evenodd"
                d="M0 13.04V10.1l26.3-3.47L51.04 4.9 93.61.58l1.9 3.29L50.7 8.7l-6.4 1.04s-5.71.35-6.58.35c-.87 0-7.61.69-7.61.69l-2.6-.52-3.8 1.04-3.81.34s-3.12-.52-3.63-.52c-.52 0-1.56.35-2.43.87C12.98 12.52 0 13.04 0 13.04Z"
                clipRule="evenodd"
                fill={color || null}
              />
            </svg>
          </div>
        )) ||
        (tag === "h6" && (
          <div className={`${textAlign} relative ${headingClassList}`}>
            <h6
              className={`relative inline-block transform -rotate-2 leading-none ${classList} ${
                colorTitle || "text-black"
              }`}
              style={{ color: color || null, fontSize: sizeFont }}
            >
              {content}
            </h6>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`fill-current ${lineAlign} ${colorTitle} ${lineWidth}`}
              viewBox="0 0 96 14"
            >
              <path
                fillRule="evenodd"
                d="M0 13.04V10.1l26.3-3.47L51.04 4.9 93.61.58l1.9 3.29L50.7 8.7l-6.4 1.04s-5.71.35-6.58.35c-.87 0-7.61.69-7.61.69l-2.6-.52-3.8 1.04-3.81.34s-3.12-.52-3.63-.52c-.52 0-1.56.35-2.43.87C12.98 12.52 0 13.04 0 13.04Z"
                clipRule="evenodd"
                fill={color || null}
              />
            </svg>
          </div>
        )) ||
        (tag === "p" && (
          <div className={`${textAlign} relative ${headingClassList}`}>
            <p
              className={`relative inline-block transform -rotate-2 leading-none ${classList} ${
                colorTitle || "text-black"
              }`}
              style={{ color: color || null, fontSize: sizeFont }}
            >
              {content}
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`fill-current ${lineAlign} ${colorTitle} ${lineWidth}`}
              viewBox="0 0 96 14"
            >
              <path
                fillRule="evenodd"
                d="M0 13.04V10.1l26.3-3.47L51.04 4.9 93.61.58l1.9 3.29L50.7 8.7l-6.4 1.04s-5.71.35-6.58.35c-.87 0-7.61.69-7.61.69l-2.6-.52-3.8 1.04-3.81.34s-3.12-.52-3.63-.52c-.52 0-1.56.35-2.43.87C12.98 12.52 0 13.04 0 13.04Z"
                clipRule="evenodd"
                fill={color || null}
              />
            </svg>
          </div>
        )) ||
        null}
    </div>
  )
}

TitleUnderlined.propTypes = {
  content: PropTypes.string,
  colorTitle: PropTypes.string,
  classList: PropTypes.string,
  tag: PropTypes.string,
}

TitleUnderlined.defaultProps = {
  content: "",
  colorTitle: "",
  classList: "",
  tag: "",
}

export default TitleUnderlined
